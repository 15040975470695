<template>
  <v-dialog
    v-model="canOpenDialog"
    :max-width="1000"
    content-class="elevation-0"
    persistent
  >
    <v-card
      class="form-fields-card"
    >
      <v-card-title
        style="background-color: #eeeeee;"
      >
        {{ dialogTitle }}
        <v-spacer />
        <v-btn
          fab
          x-small
          class="pa-0 dialog-close-button no-background-hover"
          style="background: transparent;"
          elevation="0"
          @click="closeModal"
        >
          <v-icon
            color="grey darken-2"
            size="1.8rem"
          >
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <div
        class="tabs-wrapper"
      >
        <v-tabs
          v-model="currentTab"
          vertical
          @change="getClickedTab"
        >
          <template
            v-for="tab in tabs"
          >
            <v-tab
              :key="tab.key"
              style="height: 3rem; width: 10rem;"
              class="justify-start"
            >
              {{ tab.label }}
              <v-icon
                v-if="checkForValidationErrors(tab)"
                class="ml-1 red-muted--text"
              >
                mdi-alert-circle-outline
              </v-icon>
            </v-tab>

            <v-tab-item
              :key="tab.key + '_content'"
              eager
            >
              <v-card
                flat
              >
                <v-card-text
                  style="height: 70vh; overflow-y: scroll;"
                >
                  <excavation-tab-form-fields
                    :tab="tab"
                    :form-data="formData"
                    :confirmed-coordinates="confirmedCoordinates"
                    :edit-address="editAddress"
                    :can-edit="canEdit"
                    :reset-location-modal-opening="reset"
                    :validation-errors="validationErrors"
                    :drop-down-values="dropDownValues"
                    :is-invalid-date="isInvalidDate"
                    :user-input="userInput"
                    :map-class="mapClass"
                    :upload-documents="uploadedDocuments"
                    @close-modal="closeModal"
                    @update-data="updateFormData"
                    @update-documents="updateDocuments"
                    @update-confirmed-coordinates="updateCoords"
                    @update-errors="updateErrors"
                    @update-is-invalid-date="updateIsInvalidDate"
                    @update-location-modal-open-status="updateLocationModalOpenStatus"
                    @update-user-input="updateUserInput"
                    @save-data="saveData"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>
          </template>
        </v-tabs>
      </div>
      <v-card-actions
        class="justify-end pt-5 pb-5"
        style="background-color: #eeeeee;"
      >
        <v-btn
          v-show="editing"
          color="secondary"
          class="mr-2"
          @click="exportPdf(formData.id)"
        >
          NC Izvještaj
          <v-icon
            class="ml-2"
          >
            mdi-file-chart
          </v-icon>
        </v-btn>
        <v-btn
          class="mr-2 error"
          @click="closeModal"
        >
          {{ $t('road-maintenance.excavation_modal_close_btn') }}
          <v-icon
            class="ml-2"
          >
            mdi-file-remove
          </v-icon>
        </v-btn>
        <v-btn
          color="primary"
          class="mr-2"
          :disabled="disableSave"
          @click="saveData"
        >
          {{ $t('road-maintenance.excavation_modal_save_btn') }}
          <v-icon
            class="ml-2"
          >
            mdi-content-save-edit-outline
          </v-icon>
        </v-btn>
        <v-btn
          color="primary"
          class="mr-4"
          :disabled="disableSave"
          @click="saveDataAndCloseModal"
        >
          {{ $t('road-maintenance.excavation_modal_save_and_close_btn') }}
          <v-icon
            class="ml-2"
          >
            mdi-content-save-move-outline
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="openFailedCreatingOrderDialog"
      persistent
      max-width="700"
    >
      <v-card>
        <v-card-title>
          {{ $t('road-maintenance.excavation_modal_exception_title') }}
        </v-card-title>
        <v-card-text>
          <template
            v-if="typeof failedCreatingDialogMessage === 'object'"
          >
            <p
              v-for="(errorMessage, field) in failedCreatingDialogMessage"
              :key="field"
            >
              {{ errorMessage[0] }}
            </p>
          </template>
          <template
            v-else
          >
            <p>{{ failedCreatingDialogMessage }}</p>
          </template>
        </v-card-text>
        <v-card-actions
          class="justify-end mt-4"
          style="background-color: #eeeeee;"
        >
          <v-btn
            color="primary"
            class="mr-2"
            @click="closeFailedCreatingOrderDialog"
          >
            {{ $t('road-maintenance.excavation_modal_quit_btn') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { api } from '@/global/services/api'
import ExcavationTabFormFields from './ExcavationTabFormFields.vue'
import dayjs from 'dayjs'
import KeyBinder from '@/global/services/helpers/KeyBinder'
import { dataUriToBlob, convertToBase64 } from '@/global/services/helpers/files'

const { mapGetters, mapActions } = createNamespacedHelpers('road-maintenance/excavation-orders')

export default {
  name: 'CreateWorkOrderModal',
  components: { ExcavationTabFormFields },

  props: {
    isOpened: {
      type: Boolean,
      required: true
    },
    editItemProp: {
      type: Object,
      default: () => {}
    },
    editing: {
      type: Boolean,
      required: true
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    mapClass: {
      type: String,
      default: 'excavation-location-map-modal'
    }
  },

  data () {
    return {
      currentTab: 0,
      canOpenDialog: false,
      exceptionTitle: '',
      exceptionMessage: '',
      validationErrors: {},
      openFailedCreatingOrderDialog: false,
      failedCreatingDialogMessage: '',
      formData: {},
      uploadedDocuments: {
        images: [],
        pdfFiles: []
      },
      isInvalidDate: false,
      formDataForSending: new FormData(),
      tabs: {
        request: {
          label: this.$t('road-maintenance/excavation-service/tabs.request'),
          key: this.$t('road-maintenance/excavation-service/tabs.request').toLowerCase(),
          columns: {
            applicant: {
              type: 'dropdown',
              label: this.$t('road-maintenance/dropdown-types.applicant')
            },
            label: {
              type: 'text',
              label: this.$t('road-maintenance/excavation-service/tabs/request/columns.label')
            },
            requested_at: {
              type: 'datepicker',
              label: this.$t('road-maintenance/excavation-service/tabs/request/columns.request_date')
            },
            investor: {
              type: 'dropdown',
              label: this.$t('road-maintenance/dropdown-types.investor')
            },
            contractor: {
              type: 'dropdown',
              label: this.$t('road-maintenance/dropdown-types.contractor')
            },
            address: {
              type: 'autocomplete',
              label: this.$t('road-maintenance/excavation-service/tabs/request/columns.address')
            },
            location: {
              type: 'modal',
              label: this.$t('road-maintenance/excavation-service/tabs/request/columns.location')
            },
            city_district: {
              type: 'dropdown',
              label: this.$t('road-maintenance/dropdown-types.city_district')
            },
            road_administration: {
              type: 'dropdown',
              label: this.$t('road-maintenance/dropdown-types.road_administration')
            },
            road_category: {
              type: 'dropdown',
              label: this.$t('road-maintenance/dropdown-types.road_category')
            },
            excavation_reason: {
              type: 'dropdown',
              label: this.$t('road-maintenance/dropdown-types.excavation_reason')
            },
            investor_responsible_person: {
              type: 'dropdown',
              label: this.$t('road-maintenance/dropdown-types.investor_responsible_person')
            },
            zc_responsible_person: {
              type: 'dropdown',
              label: this.$t('road-maintenance/dropdown-types.zc_responsible_person')
            }
          }
        },
        offer: {
          label: this.$t('road-maintenance/excavation-service/tabs.offer'),
          key: this.$t('road-maintenance/excavation-service/tabs.offer').toLowerCase(),
          columns: {
            pavement: {
              type: 'text',
              label: this.$t('road-maintenance/excavation-service/tabs/offer/columns.pavement')
            },
            footpath: {
              type: 'text',
              label: this.$t('road-maintenance/excavation-service/tabs/offer/columns.footpath')
            },
            home_connection: {
              type: 'text',
              label: this.$t('road-maintenance/excavation-service/tabs/offer/columns.home_connection')
            },
            curb: {
              type: 'text',
              label: this.$t('road-maintenance/excavation-service/tabs/offer/columns.curb')
            },
            canals: {
              type: 'text',
              label: this.$t('road-maintenance/excavation-service/tabs/offer/columns.canals')
            },
            sidewalk: {
              type: 'text',
              label: this.$t('road-maintenance/excavation-service/tabs/offer/columns.sidewalk')
            },
            prp: {
              type: 'text',
              label: this.$t('road-maintenance/excavation-service/tabs/offer/columns.prp')
            },
            offer_note: {
              type: 'text',
              label: this.$t('road-maintenance/excavation-service/tabs/offer/columns.offer_note')
            }
          }
        },
        approval: {
          label: this.$t('road-maintenance/excavation-service/tabs.approval'),
          key: this.$t('road-maintenance/excavation-service/tabs.approval').toLowerCase(),
          columns: {
            consented_at: {
              type: 'datepicker',
              label: this.$t('road-maintenance/excavation-service/tabs/approval/columns.consent_date')
            },
            consent_number: {
              type: 'text',
              label: this.$t('road-maintenance/excavation-service/tabs/approval/columns.consent_number')
            },
            doc_class: {
              type: 'text',
              label: this.$t('road-maintenance/excavation-service/tabs/approval/columns.class')
            },
            registry_number: {
              type: 'text',
              label: this.$t('road-maintenance/excavation-service/tabs/approval/columns.registration_number')
            },
            work_started_at: {
              type: 'datepicker',
              label: this.$t('road-maintenance/excavation-service/tabs/approval/columns.start_date_of_work')
            },
            work_completed_at: {
              type: 'datepicker',
              label: this.$t('road-maintenance/excavation-service/tabs/approval/columns.end_date_of_work')
            },
            extended: {
              type: 'checkbox',
              label: this.$t('road-maintenance/excavation-service/tabs/approval/columns.prolonged')
            },
            regulation: {
              type: 'checkbox',
              label: this.$t('road-maintenance/excavation-service/tabs/approval/columns.regulation')
            }
          }
        },
        handover: {
          label: this.$t('road-maintenance/excavation-service/tabs.handover'),
          key: this.$t('road-maintenance/excavation-service/tabs.handover').toLowerCase(),
          columns: {
            notification_at: {
              type: 'datepicker',
              label: 'Datum odjave'
            },
            handovered_at: {
              type: 'datepicker',
              label: this.$t('road-maintenance/excavation-service/tabs/handover/columns.handover_date')
            },
            cost_place: {
              type: 'text',
              label: 'Mjesto troška'
            }
          }
        },
        cleanup: {
          label: this.$t('road-maintenance/excavation-service/tabs.cleanup'),
          key: this.$t('road-maintenance/excavation-service/tabs.cleanup').toLowerCase(),
          columns: {
            completed_at: {
              type: 'datepicker',
              label: this.$t('road-maintenance/excavation-service/tabs/cleanup/columns.cleanup_date')
            },
            pavement: {
              type: 'text',
              options: 'completed',
              label: this.$t('road-maintenance/excavation-service/tabs/offer/columns.pavement')
            },
            footpath: {
              type: 'text',
              options: 'completed',
              label: this.$t('road-maintenance/excavation-service/tabs/offer/columns.footpath')
            },
            home_connection: {
              type: 'text',
              options: 'completed',
              label: this.$t('road-maintenance/excavation-service/tabs/offer/columns.home_connection')
            },
            curb: {
              type: 'text',
              options: 'completed',
              label: this.$t('road-maintenance/excavation-service/tabs/offer/columns.curb')
            },
            canals: {
              type: 'text',
              options: 'completed',
              label: this.$t('road-maintenance/excavation-service/tabs/offer/columns.canals')
            },
            sidewalk: {
              type: 'text',
              options: 'completed',
              label: this.$t('road-maintenance/excavation-service/tabs/offer/columns.sidewalk')
            },
            prp: {
              type: 'text',
              options: 'completed',
              label: this.$t('road-maintenance/excavation-service/tabs/offer/columns.prp')
            },
            offer_note: {
              type: 'text',
              options: 'completed',
              label: this.$t('road-maintenance/excavation-service/tabs/offer/columns.offer_note')
            }
          }
        },
        documents: {
          label: 'Dokumenti',
          key: 'dokumenti',
          columns: {
            display_images: {
              type: 'display_images',
              numberOfPlaceholders: 3
            },
            display_pdf_files: {
              type: 'display_pdf_files',
              numberOfPlaceholders: 2
            }
          }
        },
        invoice: {
          label: this.$t('road-maintenance/excavation-service/tabs.invoice'),
          key: this.$t('road-maintenance/excavation-service/tabs.invoice').toLowerCase(),
          columns: {
            billed_at: {
              type: 'datepicker',
              label: this.$t('road-maintenance/excavation-service/tabs/invoice/columns.invoice_date')
            },
            invoice: {
              type: 'text',
              label: this.$t('road-maintenance/excavation-service/tabs/invoice/columns.invoice')
            },
            situation: {
              type: 'text',
              label: this.$t('road-maintenance/excavation-service/tabs/invoice/columns.situation')
            }
          }
        },
        comment: {
          label: this.$t('road-maintenance/excavation-service/tabs.comment'),
          key: this.$t('road-maintenance/excavation-service/tabs.comment').toLowerCase(),
          columns: {
            comment: {
              type: 'text',
              label: this.$t('road-maintenance/excavation-service/tabs/comment/columns.comment')
            }
          }
        }
      },
      editAddress: {},
      isEditing: false,
      reset: false,
      confirmedCoordinates: {},
      locationModalOpenStatus: false,
      userInput: '',
      disableSave: false,
      imageNamesFromEdit: [],
      pdfNamesFromEdit: []
    }
  },

  computed: {
    ...mapGetters(['dropDownValues']),

    dialogTitle () {
      if (this.editing) {
        return this.$t('road-maintenance.excavation_updating_order_dialog_title')
      }
      return this.$t('road-maintenance.excavation_creating_order_dialog_title')
    }
  },

  watch: {
    isOpened: {
      handler (value) {
        this.canOpenDialog = value
        this.reset = value
      }
    },
    async editItemProp (item) {
      for (const tabKey in this.tabs) {
        if (Object.prototype.hasOwnProperty.call(this.tabs, tabKey)) {
          const columns = this.tabs[tabKey].columns
          for (const columnKey in columns) {
            if (item['completed_' + columnKey]) {
              this.$set(this.formData, 'completed_' + columnKey, item['completed_' + columnKey])
            }
            if (Object.prototype.hasOwnProperty.call(columns, columnKey)) {
              if (typeof item[columnKey] === 'object' && item[columnKey] !== null) {
                this.$set(this.formData, columnKey, item[columnKey].id)
              }
              else {
                if (columns[columnKey].type === 'datepicker' && item[columnKey]) {
                  this.$set(this.formData, columnKey, dayjs(item[columnKey], 'DD.MM.YYYY').format('D/M/YY'))
                }
                else if (columns[columnKey].type === 'autocomplete' && item.address && item.latitude && item.longitude) {
                  this.editAddress = { y: item.latitude, x: item.longitude, label: item.address }
                  this.userInput = item.address
                  this.$set(this.formData, columnKey, { y: item.latitude, x: item.longitude, label: item.address })
                }
                else if (columns[columnKey].type === 'checkbox') {
                  this.$set(this.formData, columnKey, item[columnKey] === 1)
                }
                else {
                  this.$set(this.formData, columnKey, item[columnKey])
                }
              }
            }
          }
        }
      }
      this.$set(this.formData, 'id', item.id)
      if (item.latitude && item.longitude) {
        this.$set(this.formData, 'location', item.latitude + ' ' + item.longitude)
      }

      if (item.record_images && item.record_images.length) {
        this.imageNamesFromEdit = []
        await Promise.all(item.record_images.map(async (url) => {
          try {
            const { fileContent, fileSize } = await this.getFileContent(url)
            const fileData = await fileContent
            if (fileData) {
              // Get the file name with extension, for example image.jpg
              const filenameWithExtension = url.substring(url.lastIndexOf('/') + 1)
              // Get file name without extension, for example image
              const filenameWithoutExtension = filenameWithExtension.replace(/\.[^/.]+$/, '').replace('-imageConversion', '')
              this.uploadedDocuments.images.push({
                name: filenameWithoutExtension,
                url: fileData,
                size: fileSize
              })
              this.imageNamesFromEdit.push({
                name: filenameWithoutExtension,
                size: fileSize
              })
            }
          }
          catch (error) {
            console.error('Error updating image content:', error)
          }
        }))
      }
      else {
        this.uploadedDocuments.images = []
      }
      if (item.record_pdfs && item.record_pdfs.length) {
        this.pdfNamesFromEdit = []
        await Promise.all(item.record_pdfs.map(async (url) => {
          try {
            const { fileContent, fileSize } = await this.getFileContent(url)
            const fileData = await fileContent
            if (fileData) {
              // Get the file name with extension, for example 'example-pdf.pdf'
              const filenameWithExtension = url.substring(url.lastIndexOf('/') + 1)
              // Get file name without extension, for example 'example-pdf'
              const filenameWithoutExtension = filenameWithExtension.replace(/\.[^/.]+$/, '')
              this.uploadedDocuments.pdfFiles.push({
                name: filenameWithoutExtension,
                content: fileData,
                size: fileSize
              })
              this.pdfNamesFromEdit.push({
                name: filenameWithoutExtension,
                size: fileSize
              })
            }
          }
          catch (error) {
            console.error('Error updating pdf file content:', error)
          }
        }))
      }
      else {
        this.uploadedDocuments.pdfFiles = []
      }
    },
    editing (newVal) {
      this.isEditing = newVal
    }
  },

  async created () {
    await this.fetchSelectOptions()
    this.keyCallbacks = {
      Escape: this.handleKeyDownEscape
    }
    this.keyBinder = new KeyBinder(this.keyCallbacks)
    this.keyBinder.bind()
  },

  destroyed () {
    if (this.keyBinder) {
      this.keyBinder.unbind()
    }
  },

  methods: {
    ...mapActions(['fetchDropDownValues']),

    fetchSelectOptions () {
      return this.fetchDropDownValues()
    },

    handleKeyDownEscape () {
      if (this.canOpenDialog && !this.locationModalOpenStatus) {
        this.$emit('close-modal')
        this.currentTab = 0
        this.formData = {}
        this.editAddress = {}
        this.confirmedCoordinates = {}
        this.userInput = ''
        this.reset = true
        this.validationErrors = {}
        this.isInvalidDate = false
      }
    },

    checkForValidationErrors (tab) {
      const validationErrorKeys = Object.keys(this.validationErrors)
      return Object.keys(tab.columns).some(fieldName => {
        return validationErrorKeys.includes(fieldName)
      })
    },

    getClickedTab (tabKey) {
      this.currentTab = tabKey
    },

    updateFormData (newData) {
      if (newData.location && newData.location !== '') {
        delete this.validationErrors.location
        const currentTab = this.currentTab
        this.currentTab = 1
        this.currentTab = currentTab
      }
      this.formData = newData
    },

    updateDocuments (newDocuments) {
      this.uploadedDocuments = newDocuments
    },

    updateUserInput (val) {
      this.userInput = val
    },

    updateCoords (val) {
      this.confirmedCoordinates = val
    },

    updateErrors (newErrors) {
      this.validationErrors = newErrors
    },

    updateIsInvalidDate (val) {
      this.isInvalidDate = val
    },

    closeModal () {
      this.$emit('close-modal')
      this.currentTab = 0
      this.formData = {}
      this.uploadedDocuments.images = []
      this.uploadedDocuments.pdfFiles = []
      this.editAddress = {}
      this.userInput = ''
      this.confirmedCoordinates = {}
      this.validationErrors = {}
      this.isInvalidDate = false
    },

    updateLocationModalOpenStatus (val) {
      this.locationModalOpenStatus = val
    },

    async saveData (callbackFunction) {
      const that = this
      const hasCallback = typeof callbackFunction === 'function'
      that.disableSave = true
      try {
        await this.sendData()
        this.userInput = ''
        if (hasCallback) {
          callbackFunction(true)
        }
        setTimeout(() => {
          that.disableSave = false
        }, 1000)
      }
      catch (e) {
        console.log(e)
        if (hasCallback) {
          callbackFunction(false)
        }
        this.openFailedCreatingOrderDialog = true
        setTimeout(() => {
          that.disableSave = false
        }, 1000)
      }
    },

    async saveDataAndCloseModal () {
      await this.saveData((success) => {
        if (success) {
          this.closeModal()
        }
      })
    },

    async sendData () {
      const validated = this.validateFormData()
      let response

      if (!validated) {
        return
      }

      if (!this.editing) {
        this.populateFormFieldForSending()
        response = await api()['road-maintenance'].post('excavation-orders', this.formDataForSending)
      }
      else {
        this.prepareDataForUpdate()
        if (this.formData.id && !isNaN(this.formData.id)) {
          response = await api()['road-maintenance'].patch(`excavation-orders/${this.formData.id}`, this.formData)
        }
      }

      if (response && response.data && response.data.id) {
        const createFormDataFromFiles = async (files, formData, endpoint) => {
          if (files.length) {
            if (!this.editing || this.checkAreFilesChangedByNameAndSize(files, formData === imageFormData ? this.imageNamesFromEdit : this.pdfNamesFromEdit)) {
              for (const file of files) {
                const blob = dataUriToBlob(file.url || file.content)
                const fileType = formData === imageFormData ? 'image/jpeg' : 'application/pdf'
                const newFile = new File([blob], file.name, { type: fileType })
                formData.append(formData === imageFormData ? 'images[]' : 'pdfs[]', newFile)
              }
              await api()['road-maintenance'].post(`${endpoint}${response.data.id}`, formData)
            }
          }
          else {
            const existingFiles = formData === imageFormData ? this.imageNamesFromEdit : this.pdfNamesFromEdit
            if (existingFiles && existingFiles.length) {
              await api()['road-maintenance'].post(`${endpoint}${response.data.id}`, formData)
              if (formData === imageFormData) {
                this.imageNamesFromEdit = []
              }
              else {
                this.pdfNamesFromEdit = []
              }
            }
          }
        }

        // Send images
        const imageFormData = new FormData()
        await createFormDataFromFiles(this.uploadedDocuments.images, imageFormData, 'excavation-order/sendImages/')

        // Send pdf files
        const pdfFileFormData = new FormData()
        await createFormDataFromFiles(this.uploadedDocuments.pdfFiles, pdfFileFormData, 'excavation-order/sendPdfFiles/')
      }

      this.validationErrors = {}
      this.currentTab = 0
      if (!this.editing) {
        this.formData = {}
        this.uploadedDocuments.images = []
        this.uploadedDocuments.pdfFiles = []
      }
      this.$emit('pull-data')
    },

    checkAreFilesChangedByNameAndSize (uploadedFiles, filesToCheck) {
      if (uploadedFiles.length !== filesToCheck.length) {
        return true
      }

      uploadedFiles.sort((a, b) => {
        if (a.name !== b.name) {
          return a.name.localeCompare(b.name)
        }
        else {
          return a.size - b.size
        }
      })

      filesToCheck.sort((a, b) => {
        if (a.name !== b.name) {
          return a.name.localeCompare(b.name)
        }
        else {
          return a.size - b.size
        }
      })

      for (let i = 0; i < uploadedFiles.length; i++) {
        const uploadedFile = uploadedFiles[i]
        const fileToCheck = filesToCheck[i]

        if (uploadedFile.name !== fileToCheck.name || uploadedFile.size !== fileToCheck.size) {
          return true
        }
      }

      return false
    },

    async getFileContent (imageUrl) {
      try {
        const response = await fetch(imageUrl)
        const blob = await response.blob()
        const fileSize = blob.size / (1024 * 1024) // File size in MB
        const fileContent = convertToBase64(blob)

        return { fileContent, fileSize }
      }
      catch (error) {
        console.error('Error fetching image:', error)
        return null
      }
    },

    validateFormData () {
      const [latitude, longitude] = (this.formData.location || '').split(' ')
      const isLocationProvided = !!(latitude && longitude)
      this.validationErrors = {}

      if (!isLocationProvided) {
        this.validationErrors.location = this.$t('road-maintenance.excavation_modal_location_mandatory')
        this.openFailedCreatingOrderDialog = true
        this.failedCreatingDialogMessage = this.$t('road-maintenance.excavation_modal_location_mandatory')
        return false
      }
      return true
    },

    closeFailedCreatingOrderDialog () {
      this.openFailedCreatingOrderDialog = false
    },

    populateFormFieldForSending () {
      this.formDataForSending = new FormData()
      const [latitude, longitude] = (this.formData.location || '').split(' ')

      for (const fieldKey in this.formData) {
        const fieldValue = this.formData[fieldKey]

        let fieldExistsInTabs = false
        for (const tabKey in this.tabs) {
          const tab = this.tabs[tabKey]
          if (fieldKey in tab.columns) {
            fieldExistsInTabs = true
            break
          }
        }

        if (fieldValue !== undefined && fieldValue !== null && fieldValue !== '') {
          if (fieldKey === 'location') {
            this.formDataForSending.append('latitude', latitude)
            this.formDataForSending.append('longitude', longitude)
          }
          else if (fieldKey === 'address') {
            if (fieldValue && fieldValue.label) {
              this.formDataForSending.append(fieldKey, fieldValue.label)
            }
            else {
              this.formDataForSending.append(fieldKey, fieldValue)
            }
          }
          else if (!fieldExistsInTabs) {
            this.formDataForSending.append(fieldKey, fieldValue)
          }
          else {
            this.formDataForSending.append(fieldKey, fieldValue)
          }
        }
      }
    },

    prepareDataForUpdate () {
      const [latitude, longitude] = (this.formData.location || '').split(' ')
      this.formData.latitude = latitude
      this.formData.longitude = longitude
      for (const key in this.formData) {
        const fieldValue = this.formData[key]

        if (key.includes('completed') && (fieldValue === undefined || fieldValue === null || fieldValue === '')) {
          delete this.formData[key]
        }

        if (fieldValue === undefined || fieldValue === null || fieldValue === '') {
          delete this.formData[key]
        }

        if (key === 'address' && fieldValue && fieldValue.label) {
          this.formData[key] = fieldValue.label
        }
      }
    },

    async exportPdf (id) {
      const url = '/api/road-maintenance/excavation-order-pdf'
      return fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          id: id
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          if (!res.ok) {
            const responseStatusText = res.statusText
            const errorMessage = `${responseStatusText}`
            throw new Error(errorMessage)
          }

          return res.arrayBuffer()
        })
        .then(arrayBuffer => {
          // BE endpoint sends a readable stream of bytes
          const byteArray = new Uint8Array(arrayBuffer)
          const link = window.document.createElement('a')
          const title = `Zapisnik_o_primopredaji_prijekopa_${dayjs().format('YYYYMMDDHHmmss')}.pdf`
          link.href = window.URL.createObjectURL(
            new Blob([byteArray], { type: 'application/pdf' })
          )
          link.download = title
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
        .catch(error => {
          throw new Error('Error occurred:' + error)
        })
    }
  }
}
</script>
